import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import axios from 'axios';
import * as styles from '../articlelist/articlelistpage.style';
import * as commonstyles from '../common/common.style';
import * as aboutPageStyles from './aboutPages.style';
import * as translate from '../../assets/translations/translations';
import Config from '../../app.config';
import HeaderSection from '../../components/common/header/header';
import HeaderTabletSection from '../../components/common/header/header.tablet';
import FooterSection from '../../components/common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as navigation from '../../configs/navigation';
import * as languages from '../../configs/languages';
import FontAwesome from 'react-fontawesome';
import Collapsible from 'react-collapsible';
import * as faqs from '../../configs/faqList';
import expandIcon from '../../assets/icons/expand-small_darkblue.svg';
import collapseIcon from '../../assets/icons/collapse-small_darkblue.svg';
import Search from '../search/search';

class Faq extends React.Component {

    constructor(props) {
        super();

        this.state = {
            faqs: [],
            showSearch: false
        };
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }

    componentDidMount() {
        let language = localStorage.getItem('language') || 'EN';
        let localLanguageFaqs = faqs.faqList;
        switch (language) {
            case 'DK': localLanguageFaqs = faqs.faqListDK;
                break;
            case 'NO': localLanguageFaqs = faqs.faqListNO;
                break;
            case 'UK': localLanguageFaqs = faqs.faqListUK;
                break;
            case 'SE': localLanguageFaqs = faqs.faqListSE;
                break;
            case 'FI': localLanguageFaqs = faqs.faqListFI;
                break;
        }

        this.setState({ faqs: localLanguageFaqs });
    }

    generateQuestion(question) {
        return <div className="container-fluid" {...css(commonstyles.nopadding)}>
            <div className="row">
                <div  {...css(commonstyles.pointerCursor)} className="col-md-11 col-lg-11 col-sm-11 col-11" {...css(aboutPageStyles.faqTextFont, aboutPageStyles.faqTextMargin)}>
                    {question}
                </div>

                <div className="col-md-1 col-lg-1 col-sm-1 col-1">
                    <img className="expandIcon" alt="Expand Section" src={expandIcon} />
                    <img className="collapseIcon" alt="Collapse Section" src={collapseIcon} />
                </div>
            </div>
        </div>;
    }

    validateToOpen(key) {
        var professionalInvestor = localStorage.getItem('DescribeProfessionalInvestor') || "false";
        if (professionalInvestor === "true" && key === "3") {
            localStorage.setItem('DescribeProfessionalInvestor', "false");
            return true;
        }
        return false;
    }

    generateFaqList() {
        let faqs = this.state.faqs;;
        return Object.keys(faqs).map((key, index) => {
            const faq = faqs[key];
            return (
                <div className="container-fluid" key={index}>
                    <div className="row" {...css(aboutPageStyles.headingRow)}>
                        <Collapsible key={key} trigger={this.generateQuestion(faq.question)} transitionTime={200} open={this.validateToOpen(key)}>
                            <div {...css(aboutPageStyles.faqAnswersStyle)} className="content" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                        </Collapsible>
                    </div>
                </div>
            )
        })
    }
    render() {
        return (
            <div>
                <HeaderTabletSection history={this.props.history} />
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} />
                <NavBar items={navigation.navInfo} />
                {this.state.showSearch ? <Search homepage={false} /> : null}
                <div className="row">
                    <div className="col-md-3 col-lg-3 col-sm-12 col-12">
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                        <section {...css(aboutPageStyles.heading)} role="region" aria-label="FAQ">
                            {/* <h2 {...css(styles.H1)}>FAQ</h2> */}
                            <h1 {...css(styles.H1)}>FAQ</h1>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                <br />
                                <div {...css(aboutPageStyles.collapsibleContainer)}>
                                </div>
                                {this.generateFaqList()}
                            </div>
                        </section>
                    </div>
                </div>
                <FooterSection />
            </div>
        )
    }
}

export default Faq;