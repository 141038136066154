export const footerContainer = {
    width: '100%',
    backgroundColor: '#FFF',
    padding: '55px 7% 65px'
};
export const footerParagraph = {
    // color: '#7F9BAA',
    color: '#003755',
    fontSize: '0.7500em',
    lineHeight: '18px'
};
export const footerParagraphSmall = {
    // color: '#7F9BAA',
    color: '#003755',
    fontSize: '0.6250em',
    lineHeight: '18px'
};
export const anchorLink = {
    // color: '#009fda !important',
    color: '#007BC7 !important',
    cursor: 'pointer',
    textDecoration: 'underline !important',
    '&:hover': {
        // color: '#009FDA'
        color: '#007BC7',

    },
}
export const logo = {
    '@media(min-width: 0px) and (max-width:767px)': {
        width: '125px',
        paddingTop: '15px'
    }
}
export const agreementBlock = {
    clear: 'both',
    marginTop: '10px',
    display: 'flex'
}
export const restrictedLabel = {
    width: '95%',
    float: 'left',
    margin: '-5px 0 0 15px'
}
export const link = {
    color: '#009fda !important'
}
export const agreementRadioBlock = {
    marginTop: '25px'
}
