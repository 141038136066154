import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import axios from 'axios';
import * as styles from '../articlelist/articlelistpage.style';
import * as commonstyles from '../common/common.style';
import * as aboutPageStyles from './aboutPages.style';
import * as translate from '../../assets/translations/translations';
import Config from '../../app.config';
import HeaderSection from '../../components/common/header/header';
import HeaderTabletSection from '../../components/common/header/header.tablet';
import FooterSection from '../../components/common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as navigation from '../../configs/navigation';
import * as languages from '../../configs/languages';
import Search from '../search/search';

export default class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false
        };
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }

    render() {
        return (
            <div>
                <HeaderTabletSection history={this.props.history} />
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} />
                <NavBar items={navigation.navInfo} />
                {this.state.showSearch ? <Search homepage={false} /> : null}
                <div className="row">
                    <div className="col-md-3 col-lg-3 col-sm-12 col-12">
                    </div>
                    <div className="col-md-7 col-lg-7 col-sm-12 col-12">
                        <section {...css(aboutPageStyles.heading)}>
                            <div className="col-md-2 col-lg-2 col-sm-12 col-12">
                            </div>
                            <div className="col-md-10 col-lg-10 col-sm-12 col-12" {...css(commonstyles.nopadding)} role="region" aria-label="Contact Us">
                                {/* <h2 {...css(commonstyles.H1Center)}>{translate.strings.TR13}</h2> */}
                                <h1 {...css(commonstyles.H1Center)}>{translate.strings.TR13}</h1>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-12 col-12">
                                </div>
                                <div className="col-md-10 col-lg-10 col-sm-12 col-12" {...css(commonstyles.nopadding)} role="region" aria-label="Contact Us">
                                    <p {...css(commonstyles.paragraph)} style={{ lineHeight: 'normal' }}>{translate.strings.TR218}<br />
                                        <span {...css(commonstyles.bluehighlight)} style={{ color: '#007BC7' }}> +45 33 44 00 00 </span>, {translate.strings.TR219} <a href="mailto:danskeanalyse@danskebank.dk" style={{ color: '#007BC7' }}><span style={{ textDecoration: 'underline' }}>danskeanalyse@danskebank.dk</span></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="row" {...css(aboutPageStyles.contactMargin)} role="region" aria-label="Contact Us">
                    -                </div>
                <FooterSection />
            </div>
        )
    }
}

