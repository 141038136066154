import React, { Component } from 'react';
import { css } from 'glamor';
import * as styles from './listimagetext.style';
import imageThumbnail from '../../assets/images/listarticlethumbnail.png'
import rightArrow from '../../assets/icons/arrow-link-next_darkblue.svg';
import { Link } from 'react-router-dom';
import CardThumbnail from '../../assets/images/Danske_bank_default.jpg';

export default class ListImageText extends Component {

    constructor(props) {
        super(props);
    }

    formatDate(date) {
        var date = new Date(date);
        return date.toLocaleDateString("da-DK").replace(/-/g, "/");
    }

    handleFile(filepath) {
        window.open(filepath, '_blank');
    }

    render() {
        return (
            <Link
                to={{
                    pathname: this.props.commr ? `/article/${this.props.articleid}/${this.props.langauge}/commr` : `/article/${this.props.articleid}/${this.props.langauge}`,
                    state: { message: this.props }
                }}>
                <div className="container-fluid"  {...css(styles.imageTextContainer)}>
                    <div className="row">
                        <div {...css(styles.imageContainer)} className="col-md-5 col-lg-4 col-sm-5 col-12">
                            {this.props.thumbnail == undefined || this.props.thumbnail == null ? <img alt={this.props.title} {...css(styles.thumbnail)} src={CardThumbnail} /> : <img {...css(styles.thumbnail)} alt={this.props.title} src={this.props.thumbnail} />}
                        </div>
                        <div  {...css(styles.textContent)} className="col-md-5 col-lg-6 col-sm-5 col-12" role="region" aria-label="Feed">
                            <p {...css(styles.date)}>{this.formatDate(this.props.publisheddate)}</p>
                            {/* <h3 {...css(styles.H3)}>{this.props.title}</h3> */}
                            <h1 {...css(styles.H3)}>{this.props.title}</h1>
                            <p {...css(styles.textParagraph)} dangerouslySetInnerHTML={{ __html: this.props.summary }} ></p>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-2 col-12">
                            <img {...css(styles.rightArrow)} alt={this.props.title} src={rightArrow} />
                            <span {...css(styles.readMore)}>Read More</span>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}