import React from 'react';
import HeaderSection from '../common/header/header';
import AutoSuggestSearchBar from './parts/SearchBar';
import HighlightSection from './parts/Highlights'
import MostPopularSection from './parts/MostPopular';
import FooterSection from '../common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import * as navigation from '../../configs/navigation';

class CommissionedResearch extends React.Component {
    constructor(props) {
        super(props);
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

    showSearchComponent = (value) => {
        document.getElementById("searchText").focus();
    }

    render() {
        return (
            <div>
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} commr={true} />
                <NavBar items={navigation.commrNavInfo} commr={true} />
                <AutoSuggestSearchBar />
                <HighlightSection history={this.props.history} />
                {/* <MostPopularSection history={this.props.history} /> //currenct Most popular implementation does not suit the needs */}
                <FooterSection commr={true} />
            </div>
        )
    }
}

export default CommissionedResearch;